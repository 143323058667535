/**
 * quoteSliderWithEntries
 */

import Swiper from 'swiper';

const quoteSliderWithEntries = {
    cfg: {
        slider: '.js-quoteSliderWithEntries',
        sliderOptions: {
            speed: 500,
            loop: false,
            grabCursor: true,
            autoHeight: true,
            noSwipingClass: 'no-swiping',

            // Arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            // Scrollbar Navi
            // scrollbar: '.swiper-scrollbar',

            // Fraction Navi
            /* paginationFractionRender(swiper, currentClassName, totalClassName) {
                return `<span class="${currentClassName}"></span>
                        of <span class="${totalClassName}"></span>`
            }, */

            // Slides Grid
            slidesPerView: 1, // 2.1
            slidesPerGroup: 1,
        }
    },

    init() {
        const sliders = [...document.querySelectorAll(this.cfg.slider)];
        if (sliders) {
            sliders.forEach((slider) => {
                const mySwiper = Swiper(slider, this.cfg.sliderOptions);
                mySwiper.onResize();

                setTimeout(() => {
                    mySwiper.onResize();
                }, 1000);
            });
        }
    }
};

export default quoteSliderWithEntries;
