/**
 * Blog Teaser Slideshow
 */

import Swiper from 'swiper';
import { debounce } from 'lodash';

const blogTeaserSlideshow = {
    cfg: {
        slider: '.js-blogTeaserSlideshow',
        sliderOptions: {
            speed: 500,
            loop: false,
            grabCursor: true,
            noSwipingClass: 'no-swiping',

            // Arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            // Slides Grid
            slidesPerView: 2, // 2.1
            slidesPerGroup: 2,

            // Responive
            breakpoints: {
                // when window width is <= 480px
                480: {
                    slidesPerView: 1, // 1.1
                    slidesPerGroup: 1,
                },
                // when window width is <= 667px
                667: {
                    slidesPerView: 1, // 1.1
                    slidesPerGroup: 1,
                },
                // when window width is <= 1024px
                1024: {
                    slidesPerView: 2, // 2.1
                    slidesPerGroup: 2,
                },
                // when window width is <= 1280px
                1280: {
                    slidesPerView: 2, // 2.1
                    slidesPerGroup: 2,
                },
                // when window width is <= 1680px
                1680: {
                    slidesPerView: 2, // 2.1
                    slidesPerGroup: 2,
                }
            },
        }
    },

    init() {
        const sliders = [...document.querySelectorAll(this.cfg.slider)];
        if (sliders) {
            sliders.forEach((slider) => {
                const mySwiper = Swiper(slider, this.cfg.sliderOptions);
                mySwiper.onResize();

                setTimeout(() => {
                    mySwiper.onResize();
                    this.calculateButtonOffset(slider);
                    window.addEventListener('resize', debounce(() => this.calculateButtonOffset(slider), 500));
                }, 500);
            });
        }
    },

    calculateButtonOffset(slider) {
        const imageEl = slider.querySelector('.m-image');
        const navPrev = slider.querySelector('.swiper-button-prev');
        const navNext = slider.querySelector('.swiper-button-next');
        if (imageEl) {
            const size = imageEl.getBoundingClientRect().height;
            navPrev.style.top = `${size / 2}px`;
            navNext.style.top = `${size / 2}px`;
        }
    },
};

export default blogTeaserSlideshow;
